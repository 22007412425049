
.editor-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: move;
  user-select: none;

  select:disabled,
  select[disabled] > option,
  input:disabled {
    color: #777;
    opacity: 1;
  }

  .editor-canvas{
    position: relative;
    .editor-canvas-endpoint {
      z-index: 2;
      &.editor-canvas-endpoint-target {
        // clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
        clip-path: polygon(0 0, 0 100%, 100% 50%);

        border-top-right-radius: 100px;
        border-bottom-right-radius: 100px;
        overflow: hidden;
      }
    }
    .editor-canvas-node {
      pointer-events: all;
      position: absolute;
      z-index: 1;
      box-sizing: border-box;
      width: 240px;
      cursor: pointer;
      &>*:first-child {
        overflow: hidden;
      //   min-height: 200px;
      }
    }
    .editor-canvas-node-connection {
      // path {
      //   stroke: red;
      // }
      // &:hover, &-hover {
      //   path {
      //     stroke: blue;
      //   }
      // }
      &.editor-canvas-node-connection--hover {
        z-index: 1;
      }
    }
  }
}
