.sidebar {
  // width: 25vw !important;
  // max-width: 25vw !important;
  // min-width: 25vw !important;
  min-height: 100vh !important;
  overflow: auto;
}

@media (min-width: 768px) {
  .sidebar {
    // width: 25vw !important;
    // max-width: 25vw !important;
    // min-width: 25vw !important;
    min-height: 100vh !important;
    overflow: auto;
  }
}

.resize-handle--x {
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;

  position: relative;
  box-sizing: border-box;
  width: 3px;
  height: 100vh;
  border-left-width: 3px;
  border-left-style: solid;
  border-left-color: #079248;
  border-right-width: 3px;
  border-right-style: solid;
  border-right-color: #079248;
  cursor: ew-resize;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  $handleSize: 18px;
  $handleThickness: 1.5px;
  $handleDistance: 2px;

  &:before {
    content: "";
    position: absolute;
    z-index: 1;
    top: 50%;
    right: 100%;
    height: $handleSize;
    width: $handleDistance;
    margin-top: -$handleSize/2;
    border-left-color: black;
    border-left-width: $handleThickness;
    border-left-style: solid;
  }
  &:after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 100%;
    height: $handleSize;
    width: $handleDistance;
    margin-top: -$handleSize/2;
    border-right-color: black;
    border-right-width: $handleThickness;
    border-right-style: solid;
  }
}
